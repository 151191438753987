import React, { useState, useContext, useEffect, useParam } from "react";

import AppProvider, { AppContext } from "../context/AppContext";


import ReactGA4 from "react-ga4";

import Yupform from "../components/Form/Yupform";

function Guests() {

      useEffect(() => {
        ReactGA4.initialize("G-6GXQ55LQ04");
        ReactGA4.send({ hitType: "pageview", page: "/guests" });
      }, []);
  return <Yupform />;
}

export default Guests;
