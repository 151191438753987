import React, { useEffect } from "react";

import ReactGA4 from "react-ga4";

function NotFound() {
  useEffect(() => {
    ReactGA4.initialize("G-6GXQ55LQ04");
    ReactGA4.send({ hitType: "pageview", page: "/home" });
  }, []);

  return <div className="base-layout">404 / Pagina no disponible</div>;
}

export default NotFound;
